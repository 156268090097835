import React from 'react'
import PropTypes from 'prop-types'

export default function FormItem({
  name,
  type,
  placeholder,
  component,
  required,
  minLength,
  onChange
}) {
  return (
    <div className="mb-4 w-full">
      <label
        className="block text-gray-700 text-sm font-medium mb-2 capitalize"
        htmlFor={name}
      >
        {name}
      </label>
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id={name}
        type={type || 'text'}
        placeholder={placeholder}
        name={name}
        onChange={(e) => onChange(e)}
        component={component || 'input'}
        rows={component === 'textarea' ? '4' : null}
        required={required || null}
        minLength={minLength || null}
      />
    </div>
  )
}

FormItem.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  component: PropTypes.string,
  required: PropTypes.bool,
  minLength: PropTypes.string
}
